<template>
  <el-form label-position="left" label-width="0px" class="demo-ruleForm login-container">
    <h3 class="title">系统登录</h3>
    <el-form-item>
      <el-input type="text" v-model="phone" auto-complete="off" placeholder="手机号"></el-input>
    </el-form-item>
    <el-form-item>
      <el-row>
        <el-col :span="16">
          <el-input type="text" v-model="code" auto-complete="off" placeholder="验证码"></el-input>
        </el-col>
        <el-col :span="8">
          <el-button style="width: 100%; left-margin: 10px" @click="onGetCode" :disabled="disableBtn">{{
            smsText
          }}</el-button>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-input type="password" v-model="pass" auto-complete="off" placeholder="pass"></el-input>
    </el-form-item>
    <el-form-item style="width: 100%">
      <el-button type="primary" style="width: 100%" @click.native.prevent="onLogin" :loading="logining">登录</el-button>
      <!--<el-button @click.native.prevent="handleReset2">重置</el-button>-->
    </el-form-item>
    <div class="beian" v-if="isMobileMode == false">
      <a href="http://beian.miit.gov.cn" target="_blank">桂ICP备2022006803号</a>
      <div style="width:300px;margin:0 auto; padding:20px 0;">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010202000602"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
          ><img :src="gonganIcon" style="float:left;" />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
            桂公网安备 45010202000602号
          </p></a
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import { loginApi } from "@/api/loginApi.js";
import { alertMessage } from "@/util/alertMessageUtil.js";
import browser from "@/util/browser.js";
import msgLogger from "@/util/msgLogger";
const MAX_SEC = 60;

export default {
  data() {
    return {
      phone: "",
      code: "",
      pass: "",
      logining: false,
      disableBtn: false,
      smsText: "获取验证码",
      clock: null,
      maxSec: MAX_SEC,
      isMobileMode: false,
      isKaiweitalk: true,
      gonganIcon: require("@/assets/images/gongan.png"),
    };
  },
  methods: {
    onGetCode() {
      this.disableBtn = true;
      this.clock = setInterval(() => {
        this.maxSec--;
        this.smsText = `${this.maxSec}秒`;
        if (this.maxSec <= 0) {
          this.maxSec = MAX_SEC;
          this.disableBtn = false;
          this.smsText = "获取验证码";
          clearInterval(this.clock);
        }
      }, 1000);
      loginApi.getSmsCode(this.phone).then((ret) => {
        console.log(ret);
      });
    },
    onLogin() {
      loginApi
        .quickLogin(this.phone, this.code, this.pass)
        .then((ret) => {
          console.log(ret);
          const { code, data } = ret;
          if (code == 0 && data) {
            localStorage.clear();
            localStorage.setItem("user", data);
            this.$router.push({ path: "/home-dashboard" });
          } else {
            msgLogger.error(ret.msg);
          }
        })
        .catch((err) => {
          alertMessage("系统出错, 或操作太频繁", "error");
        });
    },
  },
  mounted() {
    const isWeiXin = browser.isWeiXin();
    const isMobile = browser.isMobile();
    this.isMobileMode = isWeiXin || isMobile;
    const url = window.location.href;
    console.log("url", url);
  },
};
</script>

<style lang="less" scoped>
.login-container {
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }
  .remember {
    margin: 0px 0px 35px 0px;
  }
  .beian {
    text-align: center;
    a {
      text-decoration: none;
      color: #1a2a3a;
      &:hover {
        color: #1a2a3a;
      }

      &:visited {
        color: #1a2a3a;
      }
    }
  }
}
</style>
