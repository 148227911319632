let browser = {
  isMobile() {
    const isMobile = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/i.test(
      navigator.userAgent
    );
    return isMobile;
  },
  isWeiXin() {
    console.log(navigator.userAgent.toLowerCase());
    if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
      return true;
    } else {
      return false;
    }
  },
};

export default browser;
